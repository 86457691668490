import React, { ReactNode, useCallback, useMemo, useState } from 'react'

import { BrowserRouter } from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import LibUiGlobalStyle from '@nickel/ui/components/config/GlobalStyle'
import { HamburgerMenu, X } from '@nickel/ui/components/icons'
import Nickel from '@nickel/ui/components/icons/Nickel'
import NickelLogoIcon from '@nickel/ui/components/icons/NickelLogo'
import bo from '@nickel/ui/themes/bo'

import Menu from './Menu'
import { getMenuRight } from './Menu/config'
import Styled from './styles'
import { getMenuEntries } from './utils'

type Props = {
    logout?: () => void
    roles?: string[]
    username?: string
    hasL10NSelector?: boolean
}

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        font-family: 'Muller Narrow';
    }
`

function Header({ logout = () => undefined, roles, username, hasL10NSelector = false }: Props) {
    const [menuVisible, setMenuVisible] = useState(false)
    const toggleMenu = useCallback(() => {
        setMenuVisible((b) => !b)
    }, [])
    const entries = useMemo(() => getMenuEntries(roles), [roles])
    const MenuComponent: ReactNode = useMemo(
        () => <Menu entries={getMenuRight(username, logout, hasL10NSelector)} langSelector visible />,
        [username, logout, hasL10NSelector]
    )
    if (!roles || !username) return null
    return (
        <div className="bo-menu">
            <ThemeProvider theme={bo}>
                {window.local && <GlobalStyle />}
                {window.local && <LibUiGlobalStyle />}
                <Styled.Container data-testid="BoMenu">
                    <Styled.Hamburger onClick={toggleMenu}>{menuVisible ? <X /> : <HamburgerMenu />}</Styled.Hamburger>
                    <Styled.Logos href={window.REACT_APP_BOV2_URL}>
                        <NickelLogoIcon />
                        <Nickel />
                    </Styled.Logos>
                    <BrowserRouter>
                        <Menu entries={entries} langSelector={false} visible={menuVisible} />
                        <Styled.Right>{MenuComponent}</Styled.Right>
                    </BrowserRouter>
                </Styled.Container>
            </ThemeProvider>
        </div>
    )
}

export default Header
